import bg from '../assets/bg-texture.png';
import Me1 from '../assets/me-about.png';
import Me0 from '../assets/Me0.png';
import Profilepic from '../assets/profile-pic.png';
import pic6 from '../assets/pic6.jpg';
import pic7 from '../assets/pic7.jpg';
import pic8 from '../assets/pic8.jpg';



const Images = {
pic6,
pic7,
pic8,
bg,
Me1,
Me0,
Profilepic,
};



export default Images;


